import React from "react"
import useTranslations from "../../../utils/useTranslations"
import Logo from "../../../components/logo"
import backgrounds from "../../../components/backgrounds"
import randomNumber from "random-number-csprng";
import fileDownload from "js-file-download";
import Axios from "axios";
import { getNordVpnAdLink } from "src/utils/getNordVpnAdLink";
import BENCH_CLIENT from "src/utils/benchClient";

let cherryWinLink = "https://download-new.utorrent.com/endpoint/bittorrent/os/windows/track/stable/";
const cherryOsxLink = "https://download-new.utorrent.com/endpoint/btmac/os/osx/track/stable/"
const heightParentHeightRatio = 0.45;
const riseEndpoint = "https://d3q6kme0ccojj5.cloudfront.net";
const winFilename = "bittorrent_installer.exe";
const nordVpnLink = getNordVpnAdLink();

class DownloadsCompleteClassic extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			isChrome:false,
			isIE:false,
			isMac:false,
			fs:16,
			link: cherryWinLink,
		}

		this.innerContainerRef = React.createRef();
	}

	adjustFs(prevFs){
		let height = this.innerContainerRef.current.clientHeight
		let parentHeight = this.innerContainerRef.current.offsetParent.clientHeight
		let fs = prevFs - .5
		if(fs <= 12) {
			return
		}
		if((height/parentHeight) > heightParentHeightRatio){
			this.setState({fs:fs})
		}
	}


	componentDidUpdate(prevProps, prevState){
		if((this.state.fs !== prevState.fs)){
			this.adjustFs(this.state.fs)
		}
	}

	componentDidMount(){
		this.adjustFs(this.state.fs)

		//show a different cherry link for window 7 and below users
		const isBrowser = typeof window !== "undefined";
		const ua = isBrowser ? window.navigator.userAgent : "";
		const isWin7AndAbove = isBrowser && ua.includes("Windows NT") ? parseFloat(ua.split("Windows NT")[1].split(';')[0]) > 6.1 : undefined;
		if(isWin7AndAbove === false) {
			cherryWinLink = "https://download-new.utorrent.com/endpoint/bittorrent/track/beta/os/win7";
		}

		let cherryDownloadLink = cherryWinLink;

		if(window.gatsbyIsChrome){
			this.setState({
				isChrome: true,
				isIE: false
			})
		}
		else if(window.gatsbyisIE){
			this.setState({
				isChrome:false,
				isIE: true
			})
		}

		if(window.gatsbyIsMac){
			cherryDownloadLink = cherryOsxLink
			this.setState({
				isMac: true
			})
		}

		let locationState = this.props.location.state

		if(locationState){
			if(locationState.force){
				let forceValue = (Array.isArray(locationState.force)) ? locationState.force[0] : locationState.force;
				if(forceValue === "Win"){
					cherryDownloadLink = cherryWinLink
					this.setState({
						isMac: false
					})
				}
				else if (forceValue === "Osx"){
					cherryDownloadLink = cherryOsxLink
					this.setState({
						isMac: true
					})
				}
			}
		}

		this.setState({link: cherryDownloadLink})

		// file download action
		randomNumber(1, 100)
			.then(async (num) => {
				// attempt rise only for windows and only 10%
				if (cherryDownloadLink === cherryWinLink && num <= 100) {
					// await-ing will result in request errors to throw, hence falling back to cherry
					await Axios.get(riseEndpoint, {
						responseType: "blob",
						params: {
							ic_user_id: "11091",
							c: "1",
							l: "URL",
							v: "2.1"
						}
					})
					.then(response =>  fileDownload(response.data, winFilename));
				} else {
					throw new Error("fallback to cherry");
				}
			})
			.catch(_ => {
				setTimeout(()=>{
					window.location = cherryDownloadLink;
				}, 100)
			});

		BENCH_CLIENT.ping('load.classic_download');
	}

	render(){
		const { text, locale } = this.props.pageContext

		const t = useTranslations(text)

		var winDisplay = style.show
		var macDisplay = style.hide
		if(this.state.isMac){
			winDisplay = style.hide
			macDisplay = style.show
		}

		if (locale === "en" || locale === "ja" || locale === "zh_tw") {
			var runInstaller = <li className="my-3">{t("Run")} <span className='text-primary' style={winDisplay}>BitTorrent.exe</span><span className='text-primary' style={macDisplay}>BitTorrent.dmg</span> {t("to install")}</li>

			var tryAgain = <>
				<p className="text-gray ml-3" style={{...font.md, ...winDisplay}}>{t(`* If your download does not start automatically, please`)} <br/> <a href={`${cherryWinLink}`} className="text-primary">{t(`try again.`)}</a></p>
	      		<p className="text-gray ml-3" style={{...font.md, ...macDisplay}}>{t(`* If your download does not start automatically, please`)} <br/> <a href={`${cherryOsxLink}`} className="text-primary">{t(`try again.`)}</a></p>
			</>
		}
		else{
			runInstaller = t(`Run BitTorrent.dmg to install`)
			runInstaller = (this.state.isMac) ? 
				runInstaller.replace('BitTorrent.dmg', `<span class='text-primary'>BitTorrentWeb.dmg</span>`) : 
				runInstaller.replace('BitTorrent.dmg', `<span class='text-primary'>BitTorrent.exe</span>`)
			runInstaller = <li className="my-3" dangerouslySetInnerHTML={{__html: runInstaller}}></li>

			tryAgain = <>
				<p className="text-gray ml-3" style={{...font.md, ...winDisplay}}> <a href={`${cherryWinLink}`} className="text-primary">{t(`* If your download does not start automatically, please try again.`)}</a></p>
	      		<p className="text-gray ml-3" style={{...font.md, ...macDisplay}}> <a href={`${cherryOsxLink}`} className="text-primary">{t(`* If your download does not start automatically, please try again.`)}</a></p>
	      		<br/>
			</>
		}

		if (!this.state.isMac) {
			const catLink = (locale) ? `url(/images/pro-free-trial/cat-14days-${locale}.png)` : `url(/images/pro-free-trial/cat-14days-en.png)`
			const catStyle = {
				background: catLink,
				backgroundSize: 'contain',
				backgroundRepeat: 'no-repeat',
				aspectRatio: '1083/900',
				width: '100%',
				display: 'block',
			}

			let tryAgain = <p className="text-gray ml-3 text-size-16" style={{...font.md}}> <a href={`${this.state.link}`} className="text-primary">{t(`* If your download does not start automatically, please try again.`)}</a></p>

			if (locale === "en" || locale === "ja" || locale === "zh_tw") {
  					tryAgain = <p className="text-gray ml-3 text-size-16" style={{...font.md}}>{t(`* If your download does not start automatically, please`)} <br/> <a href={`${this.state.link}`} className="text-primary">{t(`try again.`)}</a></p>
  			}

			return (
				<div className="download-complete-container download-complete-container-light top-section-container d-flex align-items-center flex-column overflow-auto background-noise-light" style={backgrounds.lightBg}>
					<div className="container pt-0 px-5 mb-5 download-complete-inner-container" ref={this.innerContainerRef} style={{fontSize:`${this.state.fs}px`}}>
						<p className="text-dark p-absolute text-center font-weight-bold" style={{fontSize:`${this.state.fs*2}px`, ...adStyle.headerSpacing}}>{t("Thank you for downloading")}</p>
						<div className="row">
							<div className="col-lg-7 col-md-6 d-flex flex-column justify-content-center">
								<Logo productName="Classic" color="black" tag="p" fsMax={26} fsCoefficient={18} className="pdlp-logo text-left ml-3"/>
								<ul className="m-0 download-complete-list text-left" style={adStyle.font_md}>
									<li  className="my-3">{t("Wait for the download to finish*")}</li>
									{runInstaller}
									<li className="my-3">{t("BitTorrent Classic will run once installed")}</li>
								</ul>
								{tryAgain}
							</div>
							<div className="col-lg-5 col-md-6 text-center" style={{fontSize:`${this.state.fs}px`}}>
								<a id="downloads-complete-pro-free-trial" target="_blank" href="https://store.bittorrent.com/849/purl-btprotrialweb14" style={catStyle}>
								</a>
							</div>
						</div>
						<div className="row mt-4">
							<div className="col-12">
								<a target="_blank" href={nordVpnLink} style={nordStyle}>
								</a>
							</div>
						</div>
					</div>
					<div className="download-complete-bottom-container d-flex align-self-start with-pro-free-trial" />
				</div>
			)
		}
		else {
			return (
				<div className="download-complete-container download-complete-container-light top-section-container d-flex align-items-center justify-content-end flex-column background-noise-light" style={backgrounds.lightBg}>
					<div className="container pt-0 px-5 mb-0 download-complete-inner-container" ref={this.innerContainerRef} style={{fontSize:`${this.state.fs}px`}}>
						<p className="text-dark p-absolute text-center font-weight-bold" style={{fontSize:`${this.state.fs*2}px`, ...adStyle.headerSpacing}}>{t("Thank you for downloading")}</p>
						<div className="row">
							<div className="col-lg-5 d-flex flex-column justify-content-center">
								<Logo productName="Classic" color="black" tag="p" fsMax={26} fsCoefficient={18} className="pdlp-logo text-nowrap text-left text-lg-right ml-3 ml-lg-0"/>
							</div>
							<div className="col-lg-7">
								<ul className="m-0 download-complete-list" style={font.lg}>
									<li  className="my-3">{t("Wait for the download to finish*")}</li>
									{runInstaller}
									<li className="my-3">{t("BitTorrent Classic will run once installed")}</li>
								</ul>
								{tryAgain}
							</div>
						</div>
						<div className="row mt-4">
							<div className="col-12">
								<a target="_blank" href={nordVpnLink} style={nordStyle}>
								</a>
							</div>
						</div>
				    </div>
				    <div className="download-complete-bottom-container w-100 d-flex">
				    </div>
				</div>
			)
		}
	}
}

export default DownloadsCompleteClassic

const adStyle = {
	font_lg:{
		fontSize:'2em',
	},
	font_md:{
		fontSize:'1.5em',
	},
	headerSpacing: {
		padding: '1em 0',
		margin: '1em 0'
	}
}

const font = {
	lg:{
		fontSize:'2em',
	},
	md:{
		fontSize:'1.25em',
	}
}

const nordStyle = {
	background: 'url(/images/nord-images/affiliate-features-campaign-1500x300.png)',
	backgroundSize: 'contain',
	backgroundRepeat: 'no-repeat',
	aspectRatio: '1500/300',
	width: '100%',
	display: 'block'
};

const style = {
	show:{
		display:'inline-block'
	},
	hide:{
		display:'none'
	}
}
